<template>
  <article class="register login__box">
    <h1 class="text-secondary">
      Cadastro
    </h1>

    <ui-form
      :ref="'form-register'"
      class="register__form"
      :form-data="formData"
      :sending="ui.isLoading"
      :api-errors.sync="apiErrors"
      @valid="formValid = $event"
    />

    <div class="text-xs flex justify-center align-center mb-8">
      <div class="field__checkbox mr-2">
        <input
          v-model="tosChecked"
          id="terms-conditions"
          type="checkbox"
          class="field__checkbox-input"
        >

        <label
          for="terms-conditions"
          class="field__checkbox-label"
        >
          <i class="icon icon-check" />
        </label>
      </div>

      <p>
        Aceito e concordo com os
        <a @click="ui.showTermsConditions = true">termos de uso</a>
      </p>
    </div>

    <ui-button
      :disabled="ui.isLoading || !formValid || !tosChecked"
      width="auto"
      class="mx-auto w-64"
      label="Cadastrar"
      @click="submit()"
    />

    <terms-conditions
      :is-active.sync="ui.showTermsConditions"
      @close="ui.showTermsConditions = false"
    />

    <login-confirmation
      v-if="ui.confirmationActive"
      :is-active="ui.confirmationActive"
      :user.sync="returnPatient"
      :step-to="3"
      :hide-close="true"
      @closed="ui.confirmationActive = false"
      @login-confirmed="loginCheck()"
    />
  </article>
</template>

<script>
import { mapActions } from 'vuex';
import VueJwtDecode from 'vue-jwt-decode';

import userRegister from '@/api/user/register';
import userLogin from '@/api/user/login';

import formData from '@/data/login/formRegister';

import formUtils from '@/mixins/formUtils';
import handleError from '@/mixins/handleError';

import uiButton from '@/components/ui/Button.vue';
import uiForm from '@/components/ui/form/_form.vue';
import TermsConditions from '@/components/appointmentScheduling/TermsConditions.vue';
import LoginConfirmation from '@/components/modals/LoginConfirmation.vue';

export default {
  components: {
    uiForm,
    uiButton,
    TermsConditions,
    LoginConfirmation,
  },

  mixins: [handleError, formUtils],

  data() {
    return {
      formData,
      formValid: false,
      recovery: false,
      tosChecked: false,
      login: null,
      apiErrors: {},
      uploadForm: {
        profileImage: {
          label: 'Foto de perfil',
          value: null,
          type: 'file',
          required: true,
          exts: ['.jpg', '.png', '.gif'],
        },
      },
      ui: {
        confirmationActive: false,
        isLoading: false,
        showTermsConditions: false,
      },
      rememberUser: false,
    };
  },

  computed: {
    returnPatient() {
      return this.expandForm(this.formData);
    },
  },

  mounted() {
    for (const field in formData) {
      this.$set(this.apiErrors, field, []);
    }
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar']),

    previewImage(file) {
      this.uploadForm.profileImage.value = file;
      const myImage = document.querySelector('#image_preview');
      myImage.src = URL.createObjectURL(file);
    },

    submit() {
      const data = this.expandForm(this.formData);
      data.cpf = data.cpf.replace(/[.-]/g, '');
      data.contactPhone = data.contactPhone.replace(/[ ()-]/g, '');
      data.hasTermsOfUse = true;

      data.ddiContactPhone = this.formData.ddi.options.find(
        (option) => option.iso === data.ddi,
      ).code;

      delete data.ddi;

      this.ui.isLoading = true;
      this.toggleProgressBar(true);

      userRegister(data)
        .then(() => {
          this.ui.confirmationActive = true;
        })
        .catch((err) => this.handleApiErrors(err, this.apiErrors))
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    loginCheck() {
      const data = {};
      data['_username'] = this.formData.email.value;
      data['_password'] = this.formData['plainPassword.first'].value;

      this.ui.isLoading = true;

      userLogin()
        .login(data)
        .then((res) => {
          const decodedData = VueJwtDecode.decode(res.token);

          this.$store.dispatch('user/setSession', {
            token: res.token,
            username: decodedData.username,
            roles: decodedData.roles,
          });
          this.$router.push({ name: 'UserAccount' });
        })
        .catch((err) => this.handleError(err.response.data.message))
        .finally(() => {
          this.ui.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.form-switch {
  .form-icon {
    @apply rounded-xl;
    @apply h-4 w-8;
    @apply border border-primary border-solid bg-neutral box-content;
    @apply inline-block cursor-pointer;
    transition: background-color .1s,border .1s,box-shadow .1s,color .1s;
    &::before {
      @apply bg-white rounded-full block h-4 w-4;
      content: "";
      transition: background-color .1s,border .1s,box-shadow .1s,color .1s,margin-left .1s;
    }
  }
  input:checked+.form-icon {
      @apply bg-primary;
    &::before {
      @apply ml-4
    }
  }
}
.login {
  h1 {
    @apply text-secondary text-center;
  }
  &__box {
    @apply flex-col flex-wrap items-center justify-center h-full;
    @apply max-w-screen-md px-16 py-8 mt-0 mb-6 mx-auto;
    @apply bg-white;
    @apply shadow-xl;
    @apply rounded-4xl;
  }
  &__form {
    @apply mb-0 w-full block;
  }
  &__options {
    @apply flex justify-between items-center mb-6 text-primary;
    @apply text-xs;
    >label {
      @apply flex items-center;
    }
  }
}
</style>
