import http from '../httpConfig';

const userRegister = (data) => new Promise((resolve, reject) => {
  http.post('/user', { user: data })
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => reject(err));
});

export default userRegister;
